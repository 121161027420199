@font-face {
  font-family: 'Mulish';
  src: url('./assets/fonts/mulish/static/Mulish-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Mulish', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.red-card {
  padding: 24px;
  background: #F7E6E7;
  border-radius: 8px;
}

.green-card {
  padding: 24px;
  background: #DCFCE7;
  border-radius: 8px;
}

.ant-btn {
  background-color: #A40207 !important;
  color: white;
  font-size: 16px;
  height: 35px;
}

.ant-btn:disabled {
  background-color: #d9d9d9 !important;
}

.ant-btn:hover {
  border-color: #A40207 !important;
  color: white !important;
}

.ant-btn:disabled:hover {
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-btn.pale {
  background-color: #F6E6E7 !important;
  border: 1px solid #F7D5D6;
  border-radius: 6px;
  color: #A40207;
  padding: 8px 16px 8px 16px;
  font-size: 15px;
  height: 40px !important;
}

.ant-btn.pale:hover {
  border-color: #A40207;
  color: #A40207;
}

.menu-item {
  background-color: #A40207 !important;
}

.menu-link {
  color: #fff !important;
}

.menu-link-active .menu-link {
  border-bottom: solid 1px #fff;
  color: #fff !important;
}

.login-form .ant-form-item-label {
  text-align: center;
}

.ant-menu {
  font-family: 'Mulish', serif
}

.ant-col {
  font-family: 'Mulish', serif;
  font-size: 16px;
}

.ant-layout-footer {
  font-family: 'Mulish', serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 575px) {
  .ant-layout-footer {
    flex-direction: column;
    justify-content: center;
  }
}

.ant-layout-header {
  padding-inline: 0;
}

.ant-layout-footer {
  padding: 20px 0;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #A40207;
  border-color: #A40207;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #A40207;
  border-color: #A40207;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: #A40207;
}

.ant-checkbox-checked:after {
  border: 2px solid #A40207;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #A40207;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #A40207;
  background-color: #A40207;
}

.ant-radio-wrapper:hover .ant-radio-wrapper,
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #A40207;
}

.profile-stat-collapse .ant-collapse-header-text {
  font-weight: 600;
}

.ant-pagination-prev,
.ant-pagination-next {
  display: none !important;
}

.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover {
  border-color: #A40207;
  background-color: transparent;
}

.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item-active:hover a {
  color: black;
}

.ant-input:hover {
  border-color: #A40207;
}

.ant-input:focus {
  border-color: #A40207;
  box-shadow: 0 0 0 2px rgba(255, 13, 5, 0.1);
}

.green-badge {
  cursor: pointer;
  padding: 4px 12px;
  width: 107px;
  height: 26px;
  background: #DCFCE7;
  border: 1px solid #14532D;
  border-radius: 24px;
  color: #14532D;
}

.red-badge {
  cursor: pointer;
  padding: 4px 12px;
  width: 130px;
  height: 26px;
  background: #F7E6E7;
  border: 1px solid #A40207;
  border-radius: 24px;
  color: #A40207;
}

.dashboard .card {
  border: 1px solid #CECECE;
  border-radius: 10px;
  padding: 20px !important;
}

.dashboard .card .header {
  color: #6A6A6A;
  font-size: 14px;
}

.dashboard .card .sum {
  font-weight: 600;
}

.dashboard .card .daily {
  float: right;
  font-weight: 600;
}

.dashboard .card .content {
  margin-top: 15px;
}

.dashboard .card .daily.red {
  color: #B63539;
}

.dashboard .card .daily.green {
  color: #22C55E;
}

.ant-menu-dark.ant-menu-submenu>.ant-menu,
.ant-menu-dark>.ant-menu.ant-menu-submenu>.ant-menu {
  background: #A40207;
}

.overflow-left .ant-menu-overflow-item-rest {
  position: absolute;
  left: 0;
}

.user-icon-wrap {
  border: solid 1px;
  font-size: 22px;
  height: 25px;
  border-radius: 25px;
  padding: 2px 5px;
}

a {
  color: #A40207;
}

a:hover {
  color: #e70008;
}

.ant-tabs-tab-btn {
  font-family: 'Mulish', serif;
  font-size: 16px;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #A40207;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #A40207;
}

.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #800206;
}

.material-html img {
  width: 100% !important;
}

#pdf-download {
  display: none;
}

#header-bar {
  display: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.ant-table-wrapper .ant-table-tbody >tr >td >.ant-table-wrapper:only-child .ant-table {
  margin-inline: 0 !important;
}
.ant-spin{
  color: #a40207 !important;
}
.ant-spin .ant-spin-dot {
  font-size: 28px;
}
.ant-spin .ant-spin-dot-item {
  background-color: #a40207 !important;
  width: 12px;
  height: 12px;
}
.ant-spin-nested-loading >div>.ant-spin {
  height: 250px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #a40207 !important;
}
@media (max-width: 900px) {
  .ant-menu-overflow {
    margin-left: 30px;
  }
}
.ant-picker-outlined:hover, .ant-picker-outlined:focus, .ant-picker-outlined:focus-within {
  border-color: #a40207;
  box-shadow: 0 0 0 2px rgba(255, 13, 5, 0.1) !important;
}
.ant-picker-focused:hover {
  border-color: #A40207;
  box-shadow: 0 0 0 2px rgba(255, 13, 5, 0.1) !important;
}